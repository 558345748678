.App {
  text-align: center;
}

.header {
  background-color: rgb(17, 175, 175);
  padding: 1vw;
  margin-bottom: 2vw;
}

.header-link {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.song-list-table {
  width: 100%;
}

.add-song { 
  margin-bottom: 10vh;
}

.twitch-sign-in {
  text-align: right;
}

.song-list-row {
  width: 100%;
  outline: solid 1px rgb(50,50,50);
  height: 5vw;
  font-size: 1.3vw;
  
}

.song-list-header {
  font-weight: bold;
}

.song-list-row div {
  text-align: center;
}

.now-playing-title {
  font-size: 3vw;
}
.now-playing-artist {
  font-size: 2vw;
}
.now-playing-requester {
  text-align: right;
}

.now-playing-container {
  
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  color: white;
  padding: 1vw;
}

.tile-game-row{
  
  
  
  
}
.tile-game-cell {
  
  width: 3vw;
  height: 3vw;
  font-size: 0.5rem;
  border: 1px solid black;
  
}
